<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Usuários Ignorados pelo Bot</p>
              <button class="btn btn-primary btn-sm ms-auto" @click="showAddNumeroIgnorado">Adicionar Usuário Banido</button>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-2 ms-auto">
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="fas fa-search"></i>
                  </span>
                  <input type="text" class="form-control" v-model="searchQuery" placeholder="Pesquisar usuários banidos">
                </div>
              </div>
            </div>
            <div v-if="loading" class="text-center my-5">
              <img src="/img/loading.gif" alt="Carregando..." />
            </div>
            <div v-else class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Número</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(numero, index) in filteredNumerosIgnorados" :key="numero.id">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ numero.nome }}</td>
                    <td>{{ numero.numero }}</td>
                    <td>
                      <button class="btn btn-sm btn-warning m-lg-2" @click="showEditNumeroIgnorado(numero)">Editar</button>
                      <button class="btn btn-sm btn-danger m-lg-2" @click="deleteNumeroIgnorado(numero.id)">Excluir</button>
                    </td>
                  </tr>
                  <tr v-if="!filteredNumerosIgnorados.length">
                    <td colspan="4" class="text-center">Nenhum usuário banido encontrado</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import axios from '@/axios';
import Swal from 'sweetalert2';

const numerosIgnorados = ref([]);
const searchQuery = ref('');
const loading = ref(true);

const filteredNumerosIgnorados = computed(() => {
  if (!searchQuery.value) return numerosIgnorados.value;
  return numerosIgnorados.value.filter(numero =>
    numero.nome.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    numero.numero.includes(searchQuery.value)
  );
});

const fetchNumerosIgnorados = async () => {
  try {
    loading.value = true;
    const response = await axios.get('/numeros-ignorados');
    numerosIgnorados.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar números ignorados:', error);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await fetchNumerosIgnorados();
});

const showAddNumeroIgnorado = async () => {
  Swal.fire({
    title: 'Adicionar Usuário Banido',
    html: `
      <input id="nome" class="swal2-input" placeholder="Nome">
      <input id="numero" class="swal2-input" value="55" maxlength="13" placeholder="Número">
    `,
    focusConfirm: false,
    didOpen: () => {
      const numeroInput = Swal.getPopup().querySelector('#numero');
      numeroInput.addEventListener('input', (e) => {
        if (e.target.value.length > 13) {
          e.target.value = e.target.value.slice(0, 13);
        }
        e.target.value = e.target.value.replace(/\s/g, '');
      });
    },
    preConfirm: () => {
      const nome = Swal.getPopup().querySelector('#nome').value;
      let numero = Swal.getPopup().querySelector('#numero').value;

      if (!nome || !numero || numero.length !== 13) {
        Swal.showValidationMessage('Nome e Número Obrigatório');
      }

      return { nome, numero };
    }
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await axios.post('/numeros-ignorados', result.value);
        const novoNumero = response.data.data;
        numerosIgnorados.value.push(novoNumero);
        Swal.fire('Sucesso', 'Usuário banido adicionado com sucesso!', 'success');
      } catch (error) {
        Swal.fire('Erro', 'Erro ao adicionar usuário banido.', 'error');
        console.log(error);
      }
    }
  });
};

const deleteNumeroIgnorado = async (numeroId) => {
  try {
    await axios.delete(`/numeros-ignorados/${numeroId}`);
    numerosIgnorados.value = numerosIgnorados.value.filter(numero => numero.id !== numeroId);
    Swal.fire('Sucesso', 'Usuário banido excluído com sucesso!', 'success');
  } catch (error) {
    Swal.fire('Erro', 'Erro ao excluir usuário banido.', 'error');
  }
};

const showEditNumeroIgnorado = async (numero) => {
  Swal.fire({
    title: 'Editar Usuário Banido',
    html: `
      <input id="nome" class="swal2-input" value="${numero.nome}" placeholder="Nome">
      <input id="numero" class="swal2-input" value="${numero.numero}" placeholder="Número">
    `,
    focusConfirm: false,
    preConfirm: () => {
      const nome = Swal.getPopup().querySelector('#nome').value;
      const numeroInput = Swal.getPopup().querySelector('#numero').value;
      if (!nome || !numeroInput) {
        Swal.showValidationMessage('Todos os campos são obrigatórios');
      }
      return { nome, numero: numeroInput };
    }
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await axios.put(`/numeros-ignorados/${numero.id}`, result.value);
        numero.nome = result.value.nome;
        numero.numero = result.value.numero;
        Swal.fire('Sucesso', 'Usuário banido editado com sucesso!', 'success');
      } catch (error) {
        Swal.fire('Erro', 'Erro ao editar usuário banido.', 'error');
      }
    }
  });
};
</script>

<style scoped>
.main-content {
  padding-top: 4rem;
}
</style>
